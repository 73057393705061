<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    persistent
    width="340px"
    :disabled="disabled || readonly">
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormat"
        prepend-inner-icon="mdi-calendar-blank-outline"
        :append-icon="clearable && dateFormat ? 'mdi-close' : ''"
        :placeholder="placeholder"
        readonly
        outlined
        dense
        hide-details
        background-color="white"
        :rules="rules"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @click:append="clearValue()">
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="minDate"
      :max="maxDate"
      :type="type">
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="onCancel()">
        ยกเลิก
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="onConfirm()">
        ยืนยัน
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'D MMM BB'
    },
    placeholder: {
      type: String,
      default: 'วัน เดือน ปี'
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    modal: false,
    date: dayjs().format('YYYY-MM-DD HH:mm')
  }),
  computed: {
    dateFormat () {
      if (!this.value) {
        return ''
        // return this.$dayjs().locale('th').format(this.format)
      }

      return this.$dayjs(this.value).locale('th').format(this.format)
    },
    minDate () {
      if (!this.min) {
        return ''
      }

      return this.$dayjs(this.min).add(7, 'h').format('YYYY-MM-DD HH:mm')
    },
    maxDate () {
      if (!this.max) {
        return ''
      }

      return this.$dayjs(this.max).add(7, 'h').format('YYYY-MM-DD HH:mm')
    }
  },
  mounted () {
    if (!this.value && this.max) {
      this.date = this.$dayjs(this.max).format('YYYY-MM-DD HH:mm')
    } else if (!this.value) {
      this.date = ''
    } else {
      this.date = this.$dayjs(this.value).format('YYYY-MM-DD HH:mm')
    }
  },
  methods: {
    clearValue () {
      this.date = this.$dayjs().format('YYYY-MM-DD HH:mm')
      this.$emit('input', '')
    },
    onCancel () {
      this.modal = false

      if (!this.value) {
        this.date = this.$dayjs().format('YYYY-MM-DD HH:mm')
      } else {
        this.date = this.$dayjs(this.value).format('YYYY-MM-DD HH:mm')
      }
    },
    onConfirm () {
      const rawTime = this.value ? this.$dayjs(this.value).format('HH:mm') : this.$dayjs().format('HH:mm')

      this.$emit('input', this.$dayjs(`${this.date} ${rawTime}`).toISOString())

      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>
